<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>放課後スイーツ部の活動日誌</v-card-title>
        <img src="../../assets/work/01-illu-sweets0226.png" class="portfolio-img">
        <v-card-text>
          <p>二次創作漫画／ロゴ</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>放課後スイーツ部の活動日誌</h2>
          <img src="../../assets/work/02-illu-sweets0226.png" alt="放課後スイーツ部の活動日誌表紙" class="portfolio-img">
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>・CLIP STUDIO PAINT<br>
          ・Illustrator<br>
          ・Photoshop</p>
          <h4>制作期間</h4>
          <p>漫画：1ヶ月程度<br>
          表紙：3日<br>
          ロゴ：2日<br>
          </p>
           <h4>作品詳細リンク</h4>
           <p ><a href="https://www.pixiv.net/artworks/96411530" target="_blank" rel="noopener noreferrer">pixiv</a></p>
           <h4>作品紹介</h4>
           <img src="../../assets/work/03-illu-sweets0226.png" alt="放課後スイーツ部の活動日誌ロゴ" class="portfolio-img">
           <br>
           <p>「ブルーアーカイブ」の二次創作漫画と表紙イラスト、ロゴを制作いたしました。<br>
           作品の雰囲気を大事にスイーツを楽しむ４人の漫画を描けて幸せでした。<br>
           ぷにけっと様主催の「せんせーのアーカイブ01（2022年2月26日）」にて頒布した作品です。<br>
           【作品ボリューム】<br>
           B5／20ページ
           </p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';

</style>