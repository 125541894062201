<template>
 <v-container id="top-home" v-cloak>
    <v-row no-gutters>
        <v-col cols="6" class="img-size">
          <router-link to="/about">
          <v-img class="img-door" :src="imgDoor" v-on:mouseover="doorOverAction" v-on:mouseleave="doorLeaveAction"></v-img>
          <p class="txt-door" v-if="doorFlag">About</p>
          </router-link>
        </v-col>
        <v-col cols="6" class="img-size">
          <router-link to="/portfolio">
          <v-img :src="imgPost" v-on:mouseover="LetterOverAction" v-on:mouseleave="LetterLeaveAction"></v-img>
          <p class="txt-letter" v-if="letterFlag">Portfolio</p>
          </router-link>
        </v-col>
    </v-row>
 </v-container>
</template>
<script>
export default {
    name: 'TopHome',
data() {
  return { 
    doorFlag:false,
    letterFlag:false,
    imgDoor:require("../assets/img/portfolio-door_off.png"),
    imgPost:require("../assets/img/portfolio-post_off.png")
  }
  },
  methods: {
                doorOverAction(){
                  this.imgDoor = require("../assets/img/portfolio-door_on.png"),
                    this.doorFlag = true
                },
                doorLeaveAction(){
                  this.imgDoor = require("../assets/img/portfolio-door_off.png"),
                    this.doorFlag = false
                },
                LetterOverAction(){
                  this.imgPost = require("../assets/img/portfolio-post_on.png"),
                    this.letterFlag = true
                },
                LetterLeaveAction(){
                  this.imgPost = require("../assets/img/portfolio-post_off.png"),
                    this.letterFlag = false
                },
                handleResize: function() {
      // resizeのたびにこいつが発火するので、ここでやりたいことをやる
      this.width = window.innerWidth;
      if(this.width <= 800){
        this.letterFlag = true
        this.doorFlag = true
      }else{
        this.letterFlag = false
        this.doorFlag = false
      }
    }
            },
            mounted: function () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  }
}  
</script>
<style lang="scss" scoped>
@import '../styles01/common/common.scss';
[v-cloak]{
    display:none;
}
#top-home{
  z-index: 100;
  width: 100%;
  height: auto;
  @include display_pc {
  margin: 0% auto 0 auto;
  width: 60%;
  height: 60%;
  }
  p.txt-door,p.txt-letter{
    font-size: 36px;
    text-align: center;
     @include tekton-pro-title();
     z-index: 100;
  }
}

</style>