<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>ココロソトープ</v-card-title>
        <img src="../../assets/work/01-illu-kafu2021.png" alt="ココロソトープ" class="portfolio-img">
        <v-card-text>
          <p>二次創作イラスト／ロゴ</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>ココロソトープ</h2>
          <img src="../../assets/work/02-illu-kafu2021.jpg" alt="ココロソトープ表紙" class="portfolio-img"><br>
          <img src="../../assets/work/03-illu-kafu2021.png" alt="可不立ち絵OFF" class="portfolio-img"><br>
          <img src="../../assets/work/04-illu-kafu2021.png" alt="可不立ち絵ON" class="portfolio-img">
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>
              ・CLIP STUDIO PAINT<br>
          ・Illustrator<br>
          ・Photoshop</p>
          <h4>制作期間</h4>
          <p>ロゴ：7日程度</p>
           <h4>作品詳細リンク</h4>
           <p><a href="https://www.pixiv.net/artworks/91063736" target="_blank" rel="noopener noreferrer">Pixiv：初音ミクTRPG非公式サプリメント「ココロソトープ」</a></p>
           <h4>作品紹介</h4>
           <p>初音ミクTRPGの二次創作作品のイラストとロゴをご依頼いただき制作いたしました。<br>
           新しいボーカロイドの可不の発売を記念して合わせて製作いたしました。<br>
           立ち絵や表紙のポーズなど打ち合わせをしていく中で彼女を作る良い経験になりました。
           </p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>