<template>
<v-app>
  <transition>
    <router-view/>
  </transition>
</v-app>
</template>
<script>
//import Home from './components/Home.vue'

export default {
  name:'App',
  components: {
  }
}
</script>
<style lang="scss">
@import '@/styles01/common/common.scss';
html,body{
  margin: 0;
  padding: 0;
}
#app{
  margin: 0;
}
</style>
