<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>月光怪奇調査官</v-card-title>
        <img src="../../assets/work/01logo-gkc2022.png" alt="月光怪奇調査官" class="portfolio-img">
        <v-card-text>
          <p>ロゴ</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>月光怪奇調査官</h2>
          <img src="../../assets/work/02logo-gkc2022.png" alt="月光怪奇調査官表紙" class="portfolio-img"><br>
          <img src="../../assets/work/03logo-gkc2022.png" alt="月光怪奇調査官白黒" class="portfolio-img">
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>
          ・Illustrator<br>
          ・Photoshop</p>
          <h4>制作期間</h4>
          <p>ロゴ：7日程度</p>
           <h4>作品詳細リンク</h4>
           <p><a href="https://fujimi-trpg-online.jp/game/storyteller.html" target="_blank" rel="noopener noreferrer">富士見書房公式「ストリテラ オモテとウラのRPG」</a></p>
           <h4>作品紹介</h4>
           <p>「ストリテラ オモテとウラのRPG」に収録されている「月光怪奇調査官」のロゴを制作いたしました。<br>
               イラストの親和性を維持しタイトルへの気持ちなど伺って制作いたしました。<br>
               光があるのにも関わらず仄暗さを意識し、キーワードの「怪奇」真相を追う「エージェント」をイメージして制作いたしました。<br>
           </p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>