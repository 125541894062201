export default {
  portfolioItems: [
    { 
      id: 14, 
      title:'放課後スイーツ部の活動日誌',
      desc: '二次創作漫画／ロゴ',
      desctxt:'あ',
      link:'https://natsun07a4.booth.pm/items/2987781',
      range: '1ヶ月',
      lang: 'CLIP STUDIO PAINT',
      img:require('@/assets/work/01-illu-sweets0226.png'),
      img_src: ('/src/assets/img/portfolio-door_off.png'),
    },
    { 
      id: 13, 
      title:'伊草ハルカ（正月）',
      desc: '二次創作イラスト',
      desctxt:'あ',
      link:'https://natsun07a4.booth.pm/items/2987781',
      range: '4日',
      lang: 'CLIP STUDIO PAINT',
      img:require('@/assets/work/01-illu-haruka0125.png'),
      img_src: ('/src/assets/img/portfolio-door_off.png'),
    },
    { 
      id: 12, 
      title:'2021年まとめ',
      desc: '二次創作イラスト',
      desctxt:'あ',
      link:'https://natsun07a4.booth.pm/items/2987781',
      range: '4日',
      lang: 'CLIP STUDIO PAINT',
      img:require('@/assets/work/01-illu-matome2021.png'),
      img_src: ('/src/assets/img/portfolio-door_off.png'),
    },
    { 
      id: 11, 
      title:'あんこらでぃお',
      desc: 'Webページ',
      desctxt:'あ',
      link:'https://natsun07a4.booth.pm/items/2987781',
      range: '1ヶ月',
      lang: 'HTML,CSS,Vue',
      img:require('@/assets/work/01web-ancora.png'),
      img_src: ('/src/assets/img/portfolio-door_off.png'),
    },
    { 
      id: 10, 
      title:'私が世界を滅ぼすその日まで',
      desc: 'ロゴ／DTP',
      desctxt:'あ',
      link:'#',
      range: '7日',
      lang: 'Illustrator,Photoshop',
      img:require('@/assets/work/01logo-wss2021.png'),
      img_src: ('/src/assets/img/portfolio-door_off.png'),
    },
    { 
      id: 9, 
      title:'東京エルドリッチエスケープ',
      desc: 'ロゴ／DTP',
      desctxt:'あ',
      link:'#',
      range: '1日',
      lang: 'Illustrator,Photoshop',
      img:require('@/assets/work/01logo-tee2021.png'),
      img_src: ('/src/assets/img/portfolio-door_off.png'),
    },
    { 
      id: 8, 
      title:'エモクロア素材',
      desc: 'エモクロアTRPG素材',
      desctxt:'エモクロアTRPGのココフォリアで使用できる素材。付箋風にメモ風のデザイン。',
      link:'https://natsun07a4.booth.pm/items/2987781',
      range: '3日',
      lang: 'Illustrator,Photoshop',
      img:require('@/assets/work/01-trpg-material2021.png'),
      img_src: ('/src/assets/img/portfolio-door_off.png'),
    },
    { id: 7,
      title:'にじのちまたね',
      desc: 'TRPGシナリオ制作／動画／DTP',
      desctxt:'ふしぎもののけRPGゆうやけこやけの二次創作合同シナリオ集。かしわもちTRPGウィークにて販売開始。本文レイアウト、動画のLive2Dを担当。',
      link:'https://natsun07a4.booth.pm/items/2905256',
      range: '4ヶ月',
      lang: 'Live2D,Photoshop,Illustrator',
      img: require('@/assets/work/01other-nizi2021.png'),
    },
    { id: 6,
      title:'他の記憶のその先へ',
      desc: 'Webページ／TRPGシナリオ制作',
      desctxt:'銀剣のステラナイツの二次創作シナリオWebサイト。「ゲームマーケット秋2020」にて販売開始。Webページにてシナリオなど、全て公開。それにアクセスできるカードも作成。',
      link:'https://natsun07a4.booth.pm/items/2529232',
      range: '1ヶ月',
      lang: 'CLIPSTUDIO,Photoshop,Illustrator,HTML,CSS,Bootstrap,シナリオ制作',
      img: require('@/assets/work/01web-other.png'),
    },
    { 
      id: 5, 
      title:'あなたガ邯ｴる言の葉ヲ霑ｽ縺かけて',
      desc: 'Webページ／TRPGシナリオ制作',
      desctxt:'銀剣のステラナイツの二次創作シナリオWebサイト。「ステラサウィン」「ゲームマーケット秋2020」にて販売開始。Webページにてシナリオなど、全て公開。それにアクセスできるカードも作成。',
      link:'https://natsun07a4.booth.pm/items/2379551',
      range: '7日',
      lang: 'CLIPSTUDIO,Photoshop,Illustrator,HTML,CSS',
      img:require('@/assets/work/01web-you.png'),
    },
    { id: 4,
      title:'KAFU「アイスクリーム」',
      desc: '二次創作イラスト',
      desctxt:'ボーカロイド可不の二次創作イラスト。',
      range: '3日',
      lang: 'CLIPSTUDIO,Photoshop',
      img:require('@/assets/work/01-illu-kafu0513.png'),
    },
    { 
      id: 3, 
      title:'同位体KAFU',
      desc: '二次創作イラスト',
      desctxt:'ボーカロイド可不の二次創作イラスト。',
      range: '3日',
      lang: 'CLIPSTUDIO,Photoshop',
      img:require('@/assets/work/01-illu-kafu0510.png'),
    },
    { 
      id: 2, 
      title:'卒業制作「祈り」',
      desc: '漆etc…',
      desctxt:'ボーカロイド可不の二次創作イラスト。',
      range: '3ヶ月',
      lang: 'CLIPSTUDIO,Photoshop',
      img: require('@/assets/work/01uni-urushi.png'),
    },
    { 
      id: 1, 
      title:'人形「エシラ」',
      desc: '石膏粘土etc…',
      desctxt:'',
      range: '10日',
      lang: '球体関節人形',
      img: require('@/assets/work/01uni-doll.png'),
    },
      ],
  }