<template>
<div class="about">
  <TopPaper/>
<AboutContents/>
<BaseFooter/>
</div>
</template>
<script>
import BaseFooter from '../components/BaseFooter.vue'
import TopPaper from '@/components/TopPaper.vue'
import AboutContents from '@/components/AboutContents.vue'
export default {
  name:'About',
  components: {
    BaseFooter,
    TopPaper,
    AboutContents
  }
}
</script>

<style lang="scss">
.about{
  margin-bottom: 100px;
}
</style>