<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>卒業制作「祈り」</v-card-title>
        <img src="../../assets/work/01uni-urushi.png" alt="卒業制作「祈り」" class="portfolio-img">
        <v-card-text>
          <p>漆etc…</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>卒業制作「祈り」</h2>
          <img src="../../assets/work/02uni-urushi.png" alt="卒業制作「祈り」" class="portfolio-img"><br>
          <img src="../../assets/work/03uni-urushi.png" alt="卒業制作「祈り」" class="portfolio-img"><br>
          
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>
            ・漆</p>
        <h4>制作期間</h4>
        <p>
            約3ヶ月</p>
        <h4>作品紹介</h4>
        <p>
            大学では漆を使い、人間のポーズを制作しました。<br>
            合掌をしている姿は日本らしい願いや祈りのポーズであると思います。<br>
            湧き出る球と尽きない人の祈りをメッセージとしています。
        </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>