<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>エモクロアTRPG素材</v-card-title>
        <img src="../../assets/work/01-trpg-material2021.png" alt="エモクロアTRPG素材" class="portfolio-img">
        <v-card-text>
          <p>TRPG素材</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>私が世界を滅ぼすその日まで</h2>
          <img src="../../assets/work/02-trpg-material2021.png" alt="エモクロアTRPG素材" class="portfolio-img"><br>
          <img src="../../assets/work/03-trpg-material2021.png" alt="エモクロアTRPG素材" class="portfolio-img"><br>
          <img src="../../assets/work/04-trpg-material2021.png" alt="エモクロアTRPG素材" class="portfolio-img"><br>
          <img src="../../assets/work/05-trpg-material2021.png" alt="エモクロアTRPG素材" class="portfolio-img"><br>
          <img src="../../assets/work/06-trpg-material2021.png" alt="エモクロアTRPG素材" class="portfolio-img">
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>
          ・Illustrator<br>
          ・Photoshop</p>
          <h4>制作期間</h4>
          <p>3日程度</p>
           <h4>作品詳細リンク</h4>
           <p><a href="https://booth.pm/ja/items/2987781" target="_blank" rel="noopener noreferrer">BOOTH</a></p>
           <h4>作品紹介</h4>
           <p>TRPGをする際に使用する【ココフォリア専用】のエモクロアTRPG共鳴感情、PC置き場素材です。<br>
               付箋をイメージしてキャラクターの立ち絵と組み合わせて使えるように意識し制作いたしました。
           </p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>