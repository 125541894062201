<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>2021年イラストまとめ</v-card-title>
        <img src="../../assets/work/01-illu-matome2021.png" alt="伊草ハルカ私服イラスト" class="portfolio-img">
        <v-card-text>
          <p>二次創作イラスト</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>

      </template>
      <v-card class="modal-title">
          <h2>2021年イラストまとめ</h2>
          <img src="../../assets/work/02-illu-matome2021.jpg" alt="伊草ハルカ私服イラスト" class="portfolio-img"><br>
          <img src="../../assets/work/03-illu-matome2021.png" alt="小鳥遊ホシノイラスト" class="portfolio-img"><br>
          <img src="../../assets/work/04-illu-matome2021.png" alt="伊草ハルカハロウィン" class="portfolio-img"><br>
          <img src="../../assets/work/05-illu-matome2021.png" alt="柚鳥ナツイラスト" class="portfolio-img"><br>
          <img src="../../assets/work/06-illu-matome2021.png" alt="陸八魔アルイラスト" class="portfolio-img"><br>
          <img src="../../assets/work/07-illu-matome2021.jpg" alt="柚鳥ナツ誕生日イラスト" class="portfolio-img"><br>
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>・CLIP STUDIO PAINT</p>
          <h4>制作期間</h4>
          <p>6〜12時間程度</p>
           <h4>作品詳細リンク</h4>
           <p ><a href="https://www.pixiv.net/users/71220681" target="_blank" rel="noopener noreferrer">pixiv</a></p>
           <h4>作品紹介</h4>
           <p>「ブルーアーカイブ」の二次創作イラストを2021年夏から制作しています。<br>
           透明感のある世界観と大好きなかわいい子をもっとたくさん描きたいと思っています。<br>
           厚塗りやすこしサッパリ塗る時を意識し全ての絵に真摯に向き合って制作しています。
           </p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>