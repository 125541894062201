<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>にじのちまたね</v-card-title>
        <img src="../../assets/work/01other-nizi2021.png" alt="にじのちまたね" class="portfolio-img">
        <v-card-text>
          <p>ロゴ／シナリオ／動画／DTP／Live2D</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>にじのちまたね</h2>
          <img src="../../assets/work/02other-nizi2021.png" alt="にじのちまたね表紙" class="portfolio-img"><br>
          <iframe width="320" height="auto" src="https://www.youtube.com/embed/eg0ZfFJXICE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>
          ・Illustrator<br>
          ・Photoshop<br>
          ・Premier Pro<br>
          ・AfterEffect<br>
          ・Live 2D</p>
          <h4>制作期間</h4>
          <p>合同誌全体：4ヶ月<br>
              ロゴ：7日程度<br>
          DTP：7日程度<br>
          動画：10日程度</p>
           <h4>作品詳細リンク</h4>
           <p><a href="https://natsun07a4.booth.pm/items/2905256" target="_blank" rel="noopener noreferrer">BOOTH</a></p>
           <h4>作品紹介</h4>
           <p>
           ふしぎもののけRPGゆうやけこやけ非公式合同シナリオ集「にじのちまたね」<br>
           主催として私自身を含め４人の合同シナリオ集です。全46ページ。<br>
           宣伝動画（表紙イラスト：ほげ）を制作するために、イラストを全てLive2Dで動かし動画を制作いたしました。<br>
           ロゴデザイン、本文装飾やDTPも担当し日本らしさと作品の持つ柔らかさを意識し制作いたしました。
           </p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>