import 'font-awesome/css/font-awesome.min.css' //
import Vue from "vue";
import Vuetify from "vuetify/lib";


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: "#a8694f",
        secondary: "#5bb0aa",
        accent: "#fdffeb",
        error: "#ffeb3b",
        warning: "#ffc107",
        info: "#ff5722",
        success: "#25e6d9",
        twitter:"#54a5e3" ,
        booth:"#e36754",
      },
    },
  },
});