<template>
 <v-container id="about-home" v-cloak>
    <v-row no-gutters>
      <v-spacer md="1" sm="0"></v-spacer>
        <v-col md="3" sm="12">
          <v-img class="img-door" :src="imgDoor"></v-img>
          <h2 class="txt-door">About Me!</h2>
          <p class="txt-door">Web&nbsp; & &nbsp;Graphic &nbsp;Designer &nbsp;& &nbsp; MORE</p>
          <p class="txt-door"></p>
        </v-col>
        <v-col md="7" sm="12" cols="12" class="txt-profile">
          <h1>n/aツナ（ナツナ）</h1>
          <p class="link-twbo-p">
          <span>mail：na.tunabone@gmail.com</span><br>
          <v-btn
          class="sns-btn"
          color="twitter"
          >
          <a class="deco-non-btn" href="https://twitter.com/natsun07a4" target="_blank">
    <v-icon>fa fa-twitter</v-icon>
    TRPG＆お仕事アカウント</a></v-btn>
    <br>
    <v-btn
          color="twitter"
          class="sns-btn"
          >
    <a class="deco-non-btn" href="https://twitter.com/n_atsuna74cos" target="_blank">
    <v-icon>fa fa-twitter</v-icon>イラストのアカウント</a>
    </v-btn>
    <br>
    <v-btn
    class="sns-btn"
          color="booth"
          >
    <a class="deco-non-btn" href="https://skima.jp/profile?id=271547" target="_blank">SKIMA</a>
    </v-btn>
    <br>
    <v-btn
    class="sns-btn"
          color="booth"
          >
    <a class="deco-non-btn" href="https://natsun07a4.booth.pm/" target="_blank">BOOTH</a>
    </v-btn>
    </p>
    <p>武蔵野美術大学工芸工業デザイン学科卒業。<br>
      卒業後にWebに出会いグラフィックやwebデザイナーをメインに仕事しています。</p>
      <h2 class="margin-20 hr-txt">スキル</h2>
      <p>Coding：HTML5・CSS3・Sass・jQuery・Vue.js <br>
        Design：Photoshop・Illustrator・Figma・CLIPSTUDIO<br>
        Other：Wordpress・Live2D</p>
        <h2 class="margin-20 hr-txt">メッセージ</h2>
        <p>
          絵を描くことが大好きです。<br>
          そして、ものづくりが好きで何かをいつも作っていたい人間。<br>
          現在はWebデザイン、イラストを中心に仕事＆同人活動中。<br>
          商業ではWeb制作、ロゴ制作を中心に。同人活動はイラスト、漫画を製作しています。<br>
          お気軽にご連絡ください。
        </p>
        </v-col>
        <v-spacer md="1" sm="0"></v-spacer>
    </v-row>
 </v-container>
</template>
<script>
export default {
    name: 'TopHome',
data() {
  return { 
    doorFlag:false,
    letterFlag:false,
    width: window.innerWidth,
    imgDoor:require("../assets/img/portfolio-door_on.png"),
    imgPost:require("../assets/img/portfolio-post_off.png")
  }
  },
  methods: {
    LetterOverAction(){
                  this.imgPost = require("../assets/img/portfolio-post_on.png"),
                    this.letterFlag = true
                },
                LetterLeaveAction(){
                  this.imgPost = require("../assets/img/portfolio-post_off.png"),
                    this.letterFlag = false
                },
    handleResize: function() {
      // resizeのたびにこいつが発火するので、ここでやりたいことをやる
      this.width = window.innerWidth;
      if(this.width <= 800){
        this.letterFlag = true
      }else{
        this.letterFlag = false
      }
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>
<style lang="scss" scoped>
@import '../styles01/common/common.scss';
[v-cloak]{
    display:none;
}
#about-home{
  padding-bottom: 80px;
  width: 100%;
  height: auto;
  line-height: 220%;
  .margin-20{
    margin-top: 20px;
  }
  .img-door{
    width: 80%;
    height: auto;
  }
  .txt-door{
    padding: 0 10px;
     @include tekton-pro-title();
     @include color-change();
  }
  .txt-profile{
    padding: 20px 10px;
    background-color:rgba(255,255,255,.8);
    border: 1px solid #a8694f;
    margin: 50px 0 0 0;
     @include m-pulus();
     .link-twbo-p a{
       padding:15px 10px;
     }
    .hr-txt{
       border-bottom: 1px solid #a8694f;
       padding-bottom: 10px;
    }
  h1,h2{
      @include color-change();
       @include b-pulus();
    }
    p{
      @include m-pulus();
      padding-top: 10px;
    }
    .sns-btn{
      margin-bottom: 12px;
    }
    .deco-non-btn{
      text-decoration: none;
      color: #ffffff;
      font-size: 14px;
    }
  }
  .txt-post{
    font-size: 36px;
    text-align: center;
     @include tekton-pro-title();
  }
  @include display_pc {
  margin: 3% 0 0 0;
  position: absolute;
  right: 0;
  th{
    width: 80px;
  }
  .margin-20{
    margin-top: 20px;
  }
  .img-door{
    top: 0;
    width: 90%;
    height: auto;
  }
  .post-50{
    padding: 50px 0 0 40px;
  }
  .img-post{
    width: 90%;
    height: auto;
  }
  .txt-door{
     @include tekton-pro-title();
  }
  .txt-profile{
    padding: 20px 30px;
    background-color:rgba(255,255,255,.8);
  }
  .txt-post{
    font-size: 36px;
    text-align: center;
     @include tekton-pro-title();
  }
}
}

</style>