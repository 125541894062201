<template>
<v-main>
<v-container>
  <v-row justify="center" class="contents-box">
    <WorkGkt2022/>
    <WorkRin2022/>
    <SweetsOne/>
    <IlluHarukaJa/>
    <IlluMatome2021/>
    <WorkAncora/>
    <WorkWss/>
    <WorkTee/>
    <TrpgMateEmo2021/>
    <TrpgNizi2021/>
    <TrpgOther2021/>
    <IlluKafu2020/>
    <TrpgYou2020/>
    <UniUrushi/>
    <UniEcila/>
  </v-row>
</v-container>
</v-main>
</template>
<script>
import constans from "../common/constans.js" // メニュー内容
import WorkGkt2022 from './work/WorkGkt2022'
import WorkRin2022 from './work/WorkRin2022'
import SweetsOne from './work/SweetsOne'
import IlluHarukaJa from './work/IlluHarukaJa'
import IlluMatome2021 from './work/IlluMatome2021'
import WorkAncora from './work/WorkAncora'
import WorkWss from './work/WorkWss'
import WorkTee from './work/WorkTee'
import TrpgMateEmo2021 from './work/TrpgMateEmo01'
import TrpgNizi2021 from './work/TrpgNizi2021'
import TrpgOther2021 from './work/TrpgOther2021'
import IlluKafu2020 from './work/IlluKafu2020'
import TrpgYou2020 from './work/TrpgYou2020'
import UniUrushi from './work/UniUrushi'
import UniEcila from './work/UniEcila'

  export default {
    name:'PortfolioContents',
    components: {
      WorkGkt2022,
      WorkRin2022,
      SweetsOne,
      IlluHarukaJa,
      IlluMatome2021,
      WorkAncora,
      WorkWss,
      WorkTee,
      TrpgMateEmo2021,
      TrpgNizi2021,
      TrpgOther2021,
      IlluKafu2020,
      TrpgYou2020,
      UniUrushi,
      UniEcila
    },
    data: () => ({
      model: null,
      dialog: false,
      portfolioItems: constans.portfolioItems ,
    }),
  }
</script>
<style lang="scss">
@import '../styles01/common/common.scss';
.mar-bottom{
    margin-bottom: 30px;
}
.v-card__text {
    width: 100%;
    text-align: center;
    padding: 20px 0 0 0;
}
.v-main__wrap {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    top: 50px;
    margin: 0 auto;
}
.contents-box{
    .v-card__title{
        @include b-pulus();
        color: #a8694f;
        font-size: 1rem;
    }
    width: 100%;
    margin-bottom: 100px;
}
.portfolio-img{
    width: 80%;
    text-align: center;
}
.modal-title{
  text-align: center;
  h2{
    color: #a8694f;
    padding: 30px 0 0 0;
    margin-bottom: 30px;
    @include b-pulus();
  }
  img{
    margin-bottom: 20px;
    filter: drop-shadow(0px 0px 10px #9dc2cf);
  }
}
.v-application {
.modal-text{
  margin: 0 auto;
  padding: 20px;
  h4{
    color: #a8694f;
    text-align: left;
    @include b-pulus();
  }
  p{
    text-align: left;
    @include m-pulus();
    line-height: 220%;
  }
  img{
    margin-top: 20px;
  }
  a{
  color: #5bb0aa;
}
}
}
@include display_pc{
.v-card__text {
    width: 80%;
    text-align: center;
    padding: 20px 0 0 0;
}
.v-main__wrap {
    flex: 1 1 auto;
    max-width: 85%;
    position: relative;
    top: 100px;
    left: 20%;
}
.contents-box{
    width: 85%;
    margin-bottom: 100px;
}
}
</style>