<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>人形「エシラ」</v-card-title>
        <img src="../../assets/work/01uni-doll.png" alt="人形「エシラ」" class="portfolio-img">
        <v-card-text>
          <p>球体関節人形</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>人形「エシラ」</h2>
          <img src="../../assets/work/02uni-doll.png" alt="人形「エシラ」" class="portfolio-img"><br>
          <img src="../../assets/work/03uni-doll.png" alt="人形「エシラ」" class="portfolio-img"><br>
          <img src="../../assets/work/04uni-doll.png" alt="人形「エシラ」" class="portfolio-img">
        <v-card-text class="modal-text">
          <h4>素材</h4>
          <p>
            ・石膏粘土…etc</p>
        <h4>制作期間</h4>
        <p>
            約10日</p>
        <h4>作品紹介</h4>
        <p>
            高校の時に魅入られた球体関節人形を大学1年生の時に制作しました。
            日本の球体関節人形らしさ、美しさと儚さ、脆さと永遠を感じて大好きな世界です。
        </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>