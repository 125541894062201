<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>あなたが綴る言の葉を追いかけて</v-card-title>
        <img src="../../assets/work/01web-you.png" alt="あなたが綴る言の葉を追いかけて" class="portfolio-img">
        <v-card-text>
          <p>ロゴ／シナリオ／Webページ</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>あなたガ邯ｴる言の葉ヲ霑ｽ縺かけて</h2>
          <img src="../../assets/work/01web-you.png" alt="あなたが綴る言の葉を追いかけて" class="portfolio-img"><br>
          <img src="../../assets/work/02web-you.png" alt="あなたが綴る言の葉を追いかけてカード" class="portfolio-img"><br>
          <iframe width="320" height="auto" src="https://www.youtube.com/embed/RoUL4oztyIs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>
            ・webコーディング(HTML、CSS）<br>
        ・Illustrator<br>
        ・Photoshop<br>
        ・Premier Pro</p>
        <h4>制作期間</h4>
        <p>
            ロゴ：3日程度<br>
            Webページ：7日程度<br>
            TRPG素材：7日程度<br>
            動画：10日程度</p>
        <h4>作品詳細リンク</h4>
        <p><a href="https://natsun07a4.booth.pm/items/2379551" target="_blank" rel="noopener noreferrer">BOOTH</a></p>
        <h4>作品紹介</h4>
        <p>
        銀剣のステラナイツの二次創作作品、非公式シナリオセッティング「あなたガ邯ｴる言の葉ヲ霑ｽ縺かけて」<br>
        全てをwebページ公開しカードを購入するとwebページへアクセスすることが可能になっています。<br>
        宣伝動画は協力者と共に制作いたしました。<br>
        素敵な世界観のTRPGをみんなに広めたい一心で制作しています。<br>
        スマートフォンでもPCでも外でも使うことができるようにと思って制作いたしました。<br>
        動きのあるページに挑戦し、アクセスしたユーザーへ高揚感を感じさせるように工夫いたしました。
        </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>