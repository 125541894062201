<template>
  <div class="top-paper" id="portfolio">
    <router-link to="/">
    <h1 class="home-tittle">
        n/a<span class="tuna-txt">ツナ</span>&nbsp;|&nbsp;Portfolio</h1>
    </router-link>
    <p class="link-twbo">
    Twitter（TRPG＆仕事）：<a href="https://twitter.com/natsun07a4" target="_blank">@natsun07a4</a><br>
    Twitter（イラスト）：<a href="https://twitter.com/n_atsuna74cos" target="_blank">@n_atsuna74cos</a><br>
    BOOTH：<a href="https://natsun07a4.booth.pm/" target="_blank">まぐろのほね</a>
    </p>
</div>
</template>

<script>
  export default {
    name:'PortfolioContents',
  }
</script>
<style lang="scss">
@import '../styles01/common/common.scss';
  #portfolio{
    @include display_pc{
    background-image: url("../assets/img/portfolio-letter.png");
    background-repeat: repeat-y;
    background-size: 30%;
    height: 110%;
    width: 100%;
    .home-tittle{
      margin: 30px 20px;
    }
      .link-twbo {
        margin: 100px 20px;
    top: 0%;
    background-color: rgb(255,255,255,.8);
    padding: 10px 20px;
    width: 350px;
    }
}
}

</style>