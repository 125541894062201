<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>東京エルドリッチエスケープ</v-card-title>
        <img src="../../assets/work/01logo-tee2021.png" alt="私が世界を滅ぼすその日まで" class="portfolio-img">
        <v-card-text>
          <p>ロゴ／DTP</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>東京エルドリッチエスケープ</h2>
          <img src="../../assets/work/03logo-tee2021.png" alt="東京エルドリッチエスケープ表紙" class="portfolio-img"><br>
          <img src="../../assets/work/02logo-tee2021.png" alt="東京エルドリッチエスケープロゴのみ" class="portfolio-img"><br>
          <img src="../../assets/work/04logo-tee2021.png" alt="東京エルドリッチエスケープ本文装飾" class="portfolio-img">
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>
          ・Illustrator<br>
          ・Photoshop</p>
          <h4>制作期間</h4>
          <p>ロゴ：7日程度<br>
          装飾：3日程度</p>
           <h4>作品詳細リンク</h4>
           <p><a href="https://ancoratrpg.com" target="_blank" rel="noopener noreferrer">制作作品の特設サイト：あんこらでぃお</a></p>
           <h4>作品紹介</h4>
           <p>オリジナルTRPGシステム合同誌『テーブルトークひみつあそび』Vol.2に収録されている「東京エルドリッチエスケープ」のロゴと本文の装飾を制作いたしました。
               特設サイトの「あんこらでぃお」と合わせてご依頼いただき制作いたしました。<br>
               作品やイラストやこのタイトルへの気持ちなど伺って制作いたしました。<br>
               「東京」は流行や新しいと古いが融合したイメージで制作し、「エルドリッチエスケープ」は殴り書きのような、誰かに伝えたい手記をイメージし制作いたしました。<br>
               本文装飾もロゴや女の子のシルエットを使用したノンブル飾りなどこだわりを詰め込ませていただきました。
           合わせて制作した特設サイトにも情報が載っていますので、ぜひご覧ください。<br>
           <a href="https://twitter.com/icochiya1414" target="_blank" rel="noopener noreferrer">イラスト製作者のいこちやさんのTwitter</a>
           </p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>