<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>伊草ハルカ（正月）</v-card-title>
        <img src="../../assets/work/01-illu-haruka0125.png" alt="伊草ハルカ正月イラスト" class="portfolio-img">
        <v-card-text>
          <p>二次創作イラスト</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>伊草ハルカ（正月）</h2>
          <img src="../../assets/work/02-illu-haruka0125.png" alt="伊草ハルカ正月イラスト" class="portfolio-img">
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>・CLIP STUDIO PAINT</p>
          <h4>制作期間</h4>
          <p>21時間程度</p>
           <h4>作品詳細リンク</h4>
           <p ><a href="https://www.pixiv.net/artworks/95765347" target="_blank" rel="noopener noreferrer">pixiv</a></p>
           <h4>作品紹介</h4>
           <p>「ブルーアーカイブ」の二次創作イラストを制作いたしました。<br>
           透き通る世界観を大好きなキャラクターである伊草ハルカちゃんで表現いたしました。<br>
           厚塗りでありながら、世界観の「透明感」を描き空気や匂いを纏うようなイラストにいたしました。
           </p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>