<template>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-col
            cols="12"
            lg="4"
            md="6"
          >
            <v-card
          class="ma-4">
          <v-row
            justify="space-around"
          >
        <v-card-title>あんこらでぃお</v-card-title>
        <img src="../../assets/work/01web-ancora.png" alt="あんこらでぃお" class="portfolio-img">
        <v-card-text>
          <p>Web制作</p>
          </v-card-text>
        <v-btn
        class="mar-bottom"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Details
        </v-btn>
      
          </v-row>
            </v-card>
          </v-col>
      </template>
      <v-card class="modal-title">
          <h2>あんこらでぃお</h2>
          <img src="../../assets/work/01web-ancora.png" alt="あんこらでぃお" class="portfolio-img">
        <v-card-text class="modal-text">
          <h4>制作スキル</h4>
          <p>・Webデザイン（figma）<br>
          ・Webコーディング（vue.js、HTML、scss）<br>
          ・Illustrator<br>
          ・Photoshop</p>
          <h4>制作期間</h4>
          <p>1ヶ月程度</p>
           <h4>作品詳細リンク</h4>
           <p><a href="https://ancoratrpg.com" target="_blank" rel="noopener noreferrer">あんこらでぃお</a></p>
           <h4>作品紹介</h4>
           <p>オリジナルTRPGシステム合同誌『テーブルトークひみつあそび』Vol.1・2の特設サイトを制作いたしました。<br>
           さまざまなシステムが入っているTRPG作品を宣伝する上で、モバイルファーストでありながら素材などはPCで使用しやすいようにまとめています。<br>
           Webデザインからコーディング、Web（Twitter）の広告用のバナーなど多く制作させていただきました。<br>
           ぜひ、公開されているwebページをご覧ください。
           </p>
           <p><a href="https://ancoratrpg.com" target="_blank" rel="noopener noreferrer">あんこらでぃお</a></p>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name:'SweetsOne',
    data: () => ({
      model: null,
      dialog: false,
    }),
  }
</script>
<style lang="scss">
@import '../../styles01/common/common.scss';
</style>