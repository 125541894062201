<template>
<div class="portfolio">
<!--<PortfolioTitle/>
<Work/>
<SideLetter/>-->
<ContentsChange/>
<ContentsOpen/>
<BaseFooter/>
</div>
</template>
<script>
import BaseFooter from '../components/BaseFooter.vue'
import ContentsChange from '../components/ContentsChange.vue'
import ContentsOpen from '../components/ContentsOpen.vue'
//import PortfolioTitle from '@/components/PortfolioTitle.vue'
//import SideLetter from '@/components/SideLetter.vue'
//import Work from '../components/Work.vue'

export default {
  name:'Portfolio',
  components: {
   // PortfolioTitle,
    BaseFooter,
    ContentsChange,
    ContentsOpen
   // SideLetter,
   // Work
  }
}
</script>

<style lang="scss">

</style>