<template>
<transition>
<div class="top-page">
  <TopPaper/>
  <TopLetter/>
  <transition><TopHome/></transition>
  <BaseFooter/>
</div>
</transition>
</template>

<script>
// @ is an alias to /src
import TopHome from '@/components/TopHome.vue'
import TopPaper from '@/components/TopPaper.vue'
import TopLetter from '@/components/TopLetter.vue'
import BaseFooter from '@/components/BaseFooter.vue'

export default {
  name: 'Home',
  components: {
    TopHome,
    TopPaper,
    TopLetter,
    BaseFooter
  }
}
</script>
<style lang="scss">
.theme--light.v-application {
    background: transparent;
}
.v-enter {
  opacity: 0;
}
.v-enter-to {
  opacity: 1;
}
.v-enter-active {
  transition: all 2s 0s ease;
}
.v-leave {
  opacity: 1;
}
.v-leave-to {
  opacity: 0;
}
.v-leave-active {
  transition: all .2s 0s ease;
}
</style>
